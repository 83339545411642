


























import { SearchBuilder } from "@/builder";
import { debounceProcess } from "@/helpers/debounce";
import { useInternalContract } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { DataListInternalContract } from "@/models/interface/salesOrder.interface";
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class SelectAvailableIc extends Vue {
  @Prop({ required: true, type: String, default: "" })
  value!: string;

  @Prop({ required: false, type: Boolean, default: false })
  disabled!: boolean;

  options: Option<DataListInternalContract>[] = [];
  loading = false;

  created(): void {
    this.onSearch = debounceProcess(this.onSearch);

    this.fetchOptions();
  }

  onChange(e: string): void {
    this.$emit("input", e);
    this.$emit("change", e);
    this.$emit("update:meta", this.findOption(e));
  }

  fetchOptions(params = new RequestQueryParams()): void {
    const { findAllAvailableIc } = useInternalContract();
    this.loading = true;
    findAllAvailableIc(params)
      .then(res => {
        this.options = res.data.map<Option<DataListInternalContract>>(item => ({
          label: item.documentNo,
          value: item.id,
          key: item.id,
          meta: item,
        }));
      })
      .finally(() => {
        this.loading = false;
      });
  }

  onSearch(value = ""): void {
    const params = new RequestQueryParams();
    const builder = new SearchBuilder();

    if (value) {
      params.search = builder
        .push(["documentNo", value], { like: "both" })
        .build();
    }

    this.fetchOptions(params);
  }

  findOption(value: string): Option<DataListInternalContract> | undefined {
    return this.options.find(item => item.value === value);
  }
}
