



































































































































































































import { DisplayBoolean } from "@/components/DisplayBoolean";
import {
  SelectAvailableAsset,
  SelectLocationInventory,
  SelectProductInStock,
  SelectProductService,
  SelectSnSales,
} from "@/components/SalesOrder";
import { SelectTaxVatOut } from "@/components/Tax";
import { useProduct, useTax } from "@/hooks";
import MNotificationVue from "@/mixins/MNotification.vue";
import { Option } from "@/models/class/option.class";
import { InventoryLineResponseDto } from "@/models/interface/inventory";
import { AssetResponseDto } from "@/models/interface/master-asset";
import {
  ProductDetailDto,
  ProductStockResponseDto,
} from "@/models/interface/master-product";
import { AccountingTaxResponseDto } from "@/models/interface/master-tax";
import {
  SalesOrderLine,
  State as SalesOrderState,
} from "@/store/salesOrder.store";
import {
  formatterNumber,
  reverseFormatNumber,
} from "@/validator/globalvalidator";
import { Component, Mixins } from "vue-property-decorator";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

/**
 * SO bisa untuk sewa ASSET atau JUAL ASSET
 *
 * bisa juga untuk JASA atau BARANG selain jasa
 */

/**
 * status data code UNIT_CODE
 * sales type rent / asset sale
 *
 * status data code PRODUCT_CODE
 * sales type product sale
 *
 * status data code OTHERS
 * sales type others
 */

/**
 * kolom product
 * assetId = diisi jika sales type RENT / ASSET SALE. diisi ketika autofill dari IC
 * mengacu ke field internalContractDetailList. setiap unitId di assign ke assetId
 * kalau RENT asset nya ngambil dari IC. kalau ASSET SALE asset nya milih sendiri
 *
 * productId = diisi ketika autofill dari IC. mengacu ke field productServices.
 * setiap product nya ditambahin ke tabel, assign ke productId
 * atau diisi ketika sales type OTHER / PRODUCT SALE
 *
 * - sales type = asset sale
 * options dari asset/available/so
 * query = status!Retired_AND_assetLocation.warehouse.branchWarehouse.secureId~
 *
 * - sales type = product sale
 * options dari product/in-stock
 * query = type!Service
 *
 * - sales type = other
 * options dari master product /product
 * query = type~Service
 *
 * - sales type = rent
 * ga bisa pilih product karena product nya ngambil dari IC
 */

/**
 * kolom uom
 * isinya diambil dari detail product yang dipilih.
 * gabisa diganti. cuman muncul ketika PRODUCT SALE / OTHER
 */

/**
 * kolom available
 * kalau RENT qty available = 0
 * kalau ASSET SALE qty available ngambil dari asset yang dipilih asset.available
 *
 * PRODUCT SALE / OTHER
 * qty available nya mengacu ke stock di inventory [inventory.available]
 *
 * reset value jika
 * - change uom
 * - change product
 * - change serial number
 */

/**
 * kolom customer location
 * - autofill dari lokasi ship to address.location
 * - autofill dari IC (service/unit)
 */

/**
 * implementasi kolom serial number
 * kolom serial number legacy implementation
 * - call API ketika ganti branch
 * - call API ketika ganti kolom product code/name based on so type product sale / other
 * - call API ketika get detail kalau sn nya falsy
 * - call API ketika ganti2 kolom serial number
 * - call API ketika search
 *
 * kalau RENT / ASSET SALE
 * opsi serial number ngambil dari SN si ASSET
 *
 * kalau PRODUCT SALE / OTHER
 * opsi serial number ngambil dari API
 * GET /product/purchase/serial-numbers/sales
 * dicari berdasarkan branch dan product
 *
 * - kolom serial number berdasarkan product kalo so rent / asset sale
 * - kolom serial number opsi nya ngambil dari API product/purchase/serial-numbers/sales
 * berdasarkan sales type product sale / others
 */

/**
 * kolom tax code taxType~VAT_OUT
 * - call API ketika mounted
 * - call API ketika search
 * - default value kalo asset sale ngambil dari preference feature_sales_tax_rate
 * - langsung call API, tidak ada kondisi apa2
 */

/**
 * column definition based on sales type
 * column rent
    datacode
    backupUnit // rent
    location // rent - product sale - unit sale
    serialNumber
    customerLocation
    qty
    qtyOutstanding
    price
    discount
    dppAmount
    taxValue
    taxCode
    subTotal

column product sale
    dataCode
    productName // product sale - other
    uom // product sale - other
    location // rent - product sale - unit sale
    serialNumber
    qtyAvailable // product sale - other - unit sale
    customerLocation
    qty
    qtyOutstanding
    price
    discount
    dppAmount
    taxValue
    taxCode
    subTotal

column other
    dataCode
    productName
    serialNumber
    uom
    qtyAvailable
    customerLocation
    qty
    qtyOutstanding
    price
    discount
    dppAmount
    taxValue
    taxCode
    subTotal

column unit sale
    dataCode
    location
    serialNumber
    qtyAvailable
    customerLocation
    qty
    qtyOutstanding
    price
    discount
    dppAmount
    taxValue
    taxCode
    subTotal
 */

/**
 * field typeData row
 * 3 tipe utama: unit | internalContract | product
 * typeData = "" jika sales type OTHER | PRODUCT SALE
 *
 * typeData = unit jika,
 * sales type nya ASSET SALE atau RENT
 *
 * typeData = internalContract jika,
 * sales type nya RENT,
 * line product nya dari detail IC field internalContractDetailList
 *
 * typeData = product jika,
 * line product dari detail IC field productServices
 */

@Component({
  components: {
    SelectSnSales,
    SelectAvailableAsset,
    SelectProductInStock,
    SelectLocationInventory,
    SelectProductService,
    SelectTaxVatOut,
    DisplayBoolean,
  },
  computed: {
    ...mapState({
      store: (st: any) => st.salesOrderStore,
      storeBaseDecimalPlace: (st: any) =>
        st.preferenceStore.baseDecimalPlace as number,
    }),
    ...mapGetters("salesOrderStore", [
      "getSoLines",
      "isSalesRent",
      "isSalesProductSale",
      "isSalesOther",
      "isAssetSale",
      "isTaxNone",
      "isSubmitted",
    ]),
  },
  methods: {
    ...mapMutations("salesOrderStore", {}),
    ...mapActions({
      addRow: "salesOrderStore/addSoLine",
      deleteRow: "salesOrderStore/deleteSoLine",
      calcSoLines: "salesOrderStore/calcSoLines",
    }),
  },
})
export default class FormTable extends Mixins(MNotificationVue) {
  store!: SalesOrderState;
  getSoLines!: SalesOrderLine[];
  isSalesRent!: boolean;
  isSalesProductSale!: boolean;
  isSalesOther!: boolean;
  isAssetSale!: boolean;
  isSubmitted!: boolean;
  isTaxNone!: boolean;
  storeBaseDecimalPlace!: number;
  addRow!: () => void;
  deleteRow!: (payload: number[]) => void;
  calcSoLines!: () => void;

  formatterNumber = formatterNumber;
  reverseFormatNumber = reverseFormatNumber;

  selectedRowKeys: number[] = [];

  //#region column definition
  commonColumns = [
    {
      title: this.$t("lbl_part_number_unit_code"),
      dataIndex: "productCode",
      width: "200px",
      scopedSlots: { customRender: "productCode" }, // combobox
    },
    {
      title: this.$t("lbl_part_name"),
      dataIndex: "productName",
      width: "200px",
      scopedSlots: { customRender: "productName" }, // combobox
    },
    {
      title: this.$t("lbl_serial_number"),
      dataIndex: "serialNumber",
      width: "200px",
      scopedSlots: { customRender: "serialNumber" }, // combobox
    },
    {
      title: this.$t("lbl_customer_location"),
      dataIndex: "customerLocation",
      width: "200px",
      scopedSlots: { customRender: "nullable" }, // display
    },
    {
      title: this.$t("lbl_qty"),
      dataIndex: "qty",
      scopedSlots: { customRender: "qty" }, // input number
    },
    {
      title: this.$t("lbl_qty_outstanding"),
      dataIndex: "qtyOutstanding",
      width: "200px",
      scopedSlots: { customRender: "number" }, // display
    },
    {
      title: this.$t("lbl_price"),
      dataIndex: "price",
      scopedSlots: { customRender: "price" }, // input number
    },
    {
      title: this.$t("lbl_discount"),
      dataIndex: "discountValue",
      scopedSlots: { customRender: "discount" }, // input number
    },
    {
      title: this.$t("lbl_dpp"),
      dataIndex: "taxableValue",
      scopedSlots: { customRender: "currency" }, // display
    },
    {
      title: this.$t("lbl_tax_amount"),
      dataIndex: "taxValue",
      scopedSlots: { customRender: "currency" }, // display
    },
    {
      title: this.$t("lbl_tax_code"),
      dataIndex: "taxCode",
      width: "250px",
      scopedSlots: { customRender: "taxCode" }, // combobox
    },
    {
      title: this.$t("lbl_subtotal"),
      dataIndex: "subTotal",
      scopedSlots: { customRender: "currency" }, // display
    },
  ];

  /**
   * columns are modified
   * {@linkcode modifyColumn}
   */
  columnsRent = [...this.commonColumns];

  /**
   * columns are modified
   * {@linkcode modifyColumn}
   */
  columnsProductSale = [...this.commonColumns];

  /**
   * columns are modified
   * {@linkcode modifyColumn}
   */
  columnsSoOther = [...this.commonColumns];

  /**
   * columns are modified
   * {@linkcode modifyColumn}
   */
  columnsAssetSale = [...this.commonColumns];
  //#endregion

  mounted(): void {
    this.modifyColumn();
  }

  /**
   * getter columns based on sales type
   */
  get getColumns() {
    if (this.isSalesRent) return [...this.columnsRent];
    else if (this.isSalesProductSale) return [...this.columnsProductSale];
    else if (this.isSalesOther) return [...this.columnsSoOther];
    else if (this.isAssetSale) return [...this.columnsAssetSale];
    else return [...this.commonColumns];
  }

  onChangeAsset(
    e: Option<AssetResponseDto> | undefined,
    row: SalesOrderLine
  ): void {
    row.assetId = e?.meta?.id || "";
    row.unitCode = e?.meta?.unitCode || "";
    row.productId = ""; // reset product id
    row.productCode = "";
    row.productName = "";
    row.serialNumber = "";
    row.locationId = e?.meta?.assetLocation.id || "";
    row.qtyAvailable = e?.meta?.available || 0;

    // format location name -> Branch - Warehouse - Rack
    row.locationName =
      (e?.meta?.assetLocation.warehouse.branchWarehouse.name || "-") +
      ", " +
      (e?.meta?.assetLocation.warehouse.name || "-") +
      ", " +
      (e?.meta?.assetLocation.name || "-");
  }

  onChangeInventory(
    e: Option<InventoryLineResponseDto> | undefined,
    row: SalesOrderLine
  ): void {
    row.locationId = e?.value || "";
    row.locationName = e?.label || "";
    row.qtyAvailable = e?.meta?.available || 0;
  }

  onChangeProduct(
    value: Option<ProductStockResponseDto> | undefined,
    row: SalesOrderLine
  ): void {
    row.productId = value?.meta?.id || "";
    row.productCode = value?.meta?.code || "";
    row.productName = value?.meta?.name || "";
    row.serialNumber = "";
    row.assetId = "";
    row.unitCode = "";
    row.locationId = "";
    row.locationName = "";
    row.qtyAvailable = 0;
    // row.taxCode = "";
    row.taxValue = 0;
    row.taxableValue = 0;
    // row.taxRate = 0;

    // set uom on this current row
    this.getDetailProduct(row.productId, res => {
      row.uomId = res.baseUnitId;
      row.uomName = res.baseUnit;

      this.calcSoLines();

      // set tax on this current row
      if (!res.salesTaxId || this.isTaxNone) return;
      row.taxCode = res.salesTaxName;
      this.getDetailTax(res.salesTaxId, ({ rate }) => {
        row.taxRate = rate;

        this.calcSoLines();
      });
    });
  }

  getDetailTax(id: string, cb: (data: AccountingTaxResponseDto) => void): void {
    const { findById } = useTax();
    findById(id).then(cb);
  }

  getDetailProduct(id: string, cb: (product: ProductDetailDto) => void): void {
    const { findById } = useProduct();
    findById(id).then(cb);
  }

  onChangeTaxCode(
    e: Option<AccountingTaxResponseDto> | undefined,
    row: SalesOrderLine
  ): void {
    row.taxCode = e?.meta?.code || "";
    row.taxRate = e?.meta?.rate || 0;

    this.calcSoLines();
  }

  handleDeleteRow(): void {
    this.showConfirmationDeleteItems(() => {
      this.deleteRow(this.selectedRowKeys);
      this.selectedRowKeys = [];
    });
  }

  onSelectChange(keys: number[]): void {
    this.selectedRowKeys = keys;
  }

  /**
   * @description insert specific column to common column
   */
  modifyColumn(): void {
    // column rent
    // insert backup unit column, and location at 2nd index
    this.columnsRent.splice(
      2,
      0,
      {
        title: this.$t("lbl_backup_unit"),
        dataIndex: "backupUnit",
        scopedSlots: { customRender: "backupUnit" }, // checkbox
      },
      {
        title: this.$t("lbl_location"),
        dataIndex: "locationName",
        width: "200px",

        // display ngambil dari asset location
        scopedSlots: { customRender: "nullable" },
      }
    );

    // product sale columns
    // insert uom, location, qty available
    this.columnsProductSale.splice(
      2,
      0,
      {
        title: this.$t("lbl_uom"),
        dataIndex: "uomName",
        scopedSlots: { customRender: "nullable" }, // autofill dari detail product
      },
      {
        title: this.$t("lbl_location"),
        dataIndex: "locationName",
        width: "200px",

        // combobox ngambil opsi dari inventory yang available > 0
        // berdasarkan branch yang dipilih
        scopedSlots: { customRender: "location" },
      },
      {
        title: this.$t("lbl_qty_available"),
        dataIndex: "qtyAvailable",
        scopedSlots: { customRender: "number" }, // display
      }
    );

    // so other
    // insert uom, qty available
    this.columnsSoOther.splice(
      3,
      0,
      {
        title: this.$t("lbl_uom"),
        dataIndex: "uomName",
        scopedSlots: { customRender: "nullable" }, // autofill dari detail product
      },
      {
        title: this.$t("lbl_qty_available"),
        dataIndex: "qtyAvailable",
        scopedSlots: { customRender: "number" }, // display
      }
    );

    // so asset sale
    // insert location
    this.columnsAssetSale.splice(2, 0, {
      title: this.$t("lbl_location"),
      dataIndex: "locationName",
      width: "200px",

      // display ngambil dari asset location
      scopedSlots: { customRender: "nullable" },
    });
    // so asset sale
    // insert location
    this.columnsAssetSale.splice(4, 0, {
      title: this.$t("lbl_qty_available"),
      dataIndex: "qtyAvailable",
      scopedSlots: { customRender: "number" }, // display
    });
  }
}
