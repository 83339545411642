






























































































































































































































































































import SelectAvailableIc from "@/components/custom/select/SelectAvailableIc.vue";
import SelectBranch from "@/components/custom/select/SelectBranch.vue";
import SelectCurrency from "@/components/custom/select/SelectCurrency.vue";
import SelectCustomer from "@/components/custom/select/SelectCustomer.vue";
import SelectSales from "@/components/custom/select/SelectSales.vue";
import SelectTaxCalculation from "@/components/custom/select/SelectTaxCalculation.vue";
import SelectTermOfPayment from "@/components/custom/select/SelectTermOfPayment.vue";
import {
  DisplayTotal,
  ModalCloseSo,
  SelectSalesType,
} from "@/components/SalesOrder";
import {
  useContactData,
  useCurrency,
  useInternalContract,
  useLocalFilter,
  useSalesOrder,
} from "@/hooks";
import MNotificationVue from "@/mixins/MNotification.vue";
import { Option } from "@/models/class/option.class";
import { ONE } from "@/models/constant/global.constant";
import {
  DATE_TIME_HOURS_DEFAULT_FORMAT,
  DEFAULT_DATE_FORMAT,
} from "@/models/constants/date.constant";
import { Mode } from "@/models/enums/global.enum";
import { PREFERENCE_FEATURE_KEY } from "@/models/enums/preference.enum";
import SALES_ORDER_STATUS from "@/models/enums/sales-order.enum";
import { SalesOrderTypeEnum } from "@/models/enums/SalesOrderType.enum";
import { TAX_CALCULATION } from "@/models/enums/tax.enum";
import { DetailContactDataDto } from "@/models/interface/contact-data";
import {
  AddressDataDto,
  ListContactDataDTO,
} from "@/models/interface/ContactDataDto.interface";
import { InternalContractGetDTO } from "@/models/interface/internal-contract";
import { DataWarehouseBranch } from "@/models/interface/logistic.interface";
import {
  SalesOrderCreateRequestDto,
  SalesOrderResponseDto,
  SalesOrderUpdateRequestDto,
} from "@/models/interface/sales-order";
import { DataListInternalContract } from "@/models/interface/salesOrder.interface";
import {
  DataMasterCurrency,
  ResponsePreference,
} from "@/models/interface/settings.interface";
import { initForm } from "@/store/resources/salesOrder.resource";
import {
  FieldGuard,
  FormValue,
  SalesOrderLine,
  State as SalesOrderState,
} from "@/store/salesOrder.store";
import {
  formatterNumber,
  reverseFormatNumber,
} from "@/validator/globalvalidator";
import { FormModel } from "ant-design-vue";
import { Component, Mixins, Prop, Ref } from "vue-property-decorator";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import FormTable from "./FormTable.vue";

// draft -> submit / close / cancel

/**
 * close so
 * tidak bisa close so kalau status
 * CLOSED
 * DELIVERED
 * PARTIAL DELIVERED
 *
 * cuman bisa close SO kalau status SUBMITTED
 *
 * field reason to close muncul kalau status CLOSED
 */

/**
 * actions
 * save draft
 * update
 * submit
 * close
 * cancel
 *
 * dokumen baru form create cuman bisa save draft / submit
 * dokumen lama bisa update, submit, close, cancel
 *
 * kalau submit, perlu cek dokumen baru atau dokumen lama
 * kalau dokumen baru field dto state diset Submitted, endpoint nya pake POST /sales
 * kalau dokumen lama, endpoint nya ini -> PUT sales/submit/{id}
 *
 * klau dokumen lama jangan lupa masukin deletedSalesOrderLineIds
 *
 * disabled actions
 * kalau status DRAFT
 * tombol yang muncul SUBMIT, CANCEL, UPDATE
 *
 * kalau status DRAFT dan SALES RENT
 * tombol yang muncul SUBMIT, CANCEL
 *
 * kalau status SUBMITTED
 * tombol yang muncul CANCEL, CLOSE
 *
 * diluar status DRAFT & SUBMIT
 * tidak ada tombol action yang ditampilkan
 */

/**
 * notes:
 * sales type rent
 * cuman pilih IC, autofill product sama header field
 * ga bisa tambah dan hapus product. cuman perlu isi angka2 dan tax
 * ga bisa update form
 *
 * sales type asset sale
 * perlu input mandatory field
 * product nya berdasarkan API GET asset/available/so
 *
 * sales type product sale
 * perlu input mandatory field
 * product nya berdasarkan API GET product/in-stock
 *
 * sales type other
 * perlu input mandatory field
 * product nya berdasarkan master product yang type nya service
 */

/**
 * IC dan RENT
 * - ketika pilih IC perlu get detail IC dulu
 * abis itu autofill form dan tabel
 * - autofill branch, currency, customer, ship to, bill to
 * sales name, customer PO number
 * - disabled field branch, customer name, ship/bill to, sales name
 * part, backup unit, location, sn, qty, price, add/delete row
 *
 * autofill table
 * - diisi dari field internalContractDetailList dan productServices
 * - sebelum autofill dari productServices perlu get detail product nya dulu
 * buat ngambil tax rate dan tax code
 * - kalau tax nya NONE ga perlu get detail product
 *
 * !! POTENTIAL ISSUE !!
 * 1. kalau awal tax EXCLUSIVE terus pilih IC, terus pilih tax NONE
 * dan ganti lagi ke tax EXCLUSIVE/INCLUSIVE, tax rate di product nya
 * hilang, perhitungan tax jadi ga sesuai
 */

/**
 * field TOP
 * - autofill dari customer
 */

type Action = "submit" | "update";

@Component({
  beforeRouteLeave(_to, _from, next) {
    this.resetStore();
    next();
  },
  components: {
    SelectSalesType,
    SelectBranch,
    SelectAvailableIc,
    SelectCustomer,
    SelectSales,
    SelectTaxCalculation,
    SelectCurrency,
    SelectTermOfPayment,
    FormTable,
    DisplayTotal,
    ModalCloseSo,
  },
  computed: {
    ...mapState({
      store: (st: any) => st.salesOrderStore as SalesOrderState,
      storeBaseDecimalPlace: (st: any) =>
        st.preferenceStore.baseDecimalPlace as number,
    }),
    ...mapGetters({
      getPreferenceByKey: "preferenceStore/GET_PREFERENCE_BY_KEY",
      getGrandTotal: "salesOrderStore/getGrandTotal",
      getSoLines: "salesOrderStore/getSoLines",
      getTotalDpp: "salesOrderStore/getTotalDpp",
      getTotalDiscount: "salesOrderStore/getTotalDiscount",
      getTotalTax: "salesOrderStore/getTotalTax",
      allowCancel: "salesOrderStore/allowCancel",
      allowClose: "salesOrderStore/allowClose",
      allowUpdate: "salesOrderStore/allowUpdate",
      allowSubmit: "salesOrderStore/allowSubmit",
      isLinesInvalid: "salesOrderStore/isLinesInvalid",
      isSalesRent: "salesOrderStore/isSalesRent",
      isSalesOther: "salesOrderStore/isSalesOther",
      isTaxNone: "salesOrderStore/isTaxNone",
      isSubmitted: "salesOrderStore/isSubmitted",
      isStatusClosed: "salesOrderStore/isStatusClosed",
    }),
  },
  methods: {
    ...mapActions({
      setEachCustomerLoc: "salesOrderStore/setEachCustomerLoc",
      setLinesIc: "salesOrderStore/setLinesIc",
      setLinesProdService: "salesOrderStore/setLinesProdService",
      clearLines: "salesOrderStore/clearLines",
      calcSoLines: "salesOrderStore/calcSoLines",
      resetStore: "salesOrderStore/resetStore",
      deleteRow: "salesOrderStore/deleteSoLine",
      restEachTaxCodeLine: "salesOrderStore/restEachTaxCodeLine",
    }),
    ...mapMutations({
      setForm: "salesOrderStore/setForm",
      setSoDetail: "salesOrderStore/setSoDetail",
    }),
  },
})
export default class FormPage extends Mixins(MNotificationVue) {
  DATE_TIME_HOURS_DEFAULT_FORMAT = DATE_TIME_HOURS_DEFAULT_FORMAT;
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  useLocalFilter = useLocalFilter;
  formatterNumber = formatterNumber;
  reverseFormatNumber = reverseFormatNumber;

  store!: SalesOrderState;
  setForm!: (payload: Partial<FormValue>) => void;
  storeBaseDecimalPlace!: number;
  getPreferenceByKey!: (key: string) => ResponsePreference | undefined;
  setEachCustomerLoc!: (payload: string) => void;
  setLinesIc!: (payload: InternalContractGetDTO) => void;
  setLinesProdService!: (payload: InternalContractGetDTO) => void;
  clearLines!: () => void;
  calcSoLines!: () => void;
  getGrandTotal!: number;
  getTotalDpp!: number;
  getTotalDiscount!: number;
  getTotalTax!: number;
  getSoLines!: SalesOrderLine[];
  resetStore!: () => void;
  setSoDetail!: (payload: SalesOrderResponseDto) => void;
  allowCancel!: boolean;
  allowClose!: boolean;
  allowUpdate!: boolean;
  allowSubmit!: boolean;
  isLinesInvalid!: boolean;
  isSalesRent!: boolean;
  isSalesOther!: boolean;
  isTaxNone!: boolean;
  isStatusClosed!: boolean;
  deleteRow!: (payload: number[]) => void;
  restEachTaxCodeLine!: () => void;

  @Prop({ type: String, default: "", required: false })
  id!: string; // sales order secure id

  @Ref("form")
  form!: FormModel;

  modalClose = {
    show: false,
    toggle(): void {
      this.show = !this.show;
    },
  };

  formRules = {
    salesType: [
      {
        required: true,
        message: this.$t("lbl_validation_required_error"),
        trigger: "change",
      },
    ],
    documentNumber: [],
    internalContractNumber: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    branchName: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    date: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    customerName: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    shipTo: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    billTo: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    salesName: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    taxCalculation: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    customerPoNumber: [],
    deliveryDate: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    currencyCode: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    currencyRate: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    top: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    notes: [
      {
        max: 2500,
        message: this.$t("lbl_validation_required_error"),
      },
    ],
    status: [],
    closeReason: [],
  };

  // FIXME: migrate to store
  formModel: FormValue = initForm(); // source of truth

  shipAdressOptions: Option[] = []; // based on customer ship to
  billAdressOptions: Option[] = []; // based on customer ship to

  // preference local state
  preference = {
    currencyCode: "", // save currency code from preference
  };

  loading = {
    cancel: false,
    update: false,
    submit: false,
    draft: false,
  };

  get notesLength(): string {
    return this.formModel.notes || "";
  }

  get isEdit(): boolean {
    return this.$route.meta ? this.$route.meta.mode === Mode.EDIT : false;
  }

  get isCreate(): boolean {
    return this.$route.meta ? this.$route.meta.mode === Mode.CREATE : false;
  }

  // get isSalesRent(): boolean {
  //   return this.formModel.salesType === SalesOrderTypeEnum.RENT;
  // }

  get isIdr(): boolean {
    return (
      !!this.formModel.currencyCode && this.formModel.currencyCode === "IDR"
    );
  }

  mounted(): void {
    // this.id = this.$route.params?.id || "";

    if (this.isEdit && this.id) {
      this.getDetailSo(this.id);
    } else if (this.isCreate) {
      this.setDefaultCurrency();
    }
  }

  /**
   * FIXME: ga perlu method ini
   * default currency bisa ambil dari local storage base_currency
   */
  async setDefaultCurrency(): Promise<void> {
    try {
      const { findById } = useCurrency();
      const pref = this.getPreferenceByKey(
        PREFERENCE_FEATURE_KEY.FEATURE_BASE_CURRENCY
      );
      if (pref && pref.value) {
        const res = await findById(pref.value);
        this.preference.currencyCode = res.currencyCode;
        this.formModel.currencyCode = res.currencyCode;
      }
    } catch (error) {
      this.formModel.currencyCode = "";
    }
  }

  onChangeBranch(e: Option<DataWarehouseBranch> | undefined): void {
    this.formModel.branchId = e?.meta?.id || "";
    this.updateStoreForm("branchId", this.formModel.branchId);
  }

  onChangeIc(e: Option<DataListInternalContract> | undefined): void {
    this.formModel.internalContractId = e?.meta?.id || "";
    this.clearLines();

    if (this.formModel.internalContractId) {
      this.autoFillFromIc(this.formModel.internalContractId);
    }
  }

  async autoFillFromIc(id: string): Promise<void> {
    const { findById } = useInternalContract();
    try {
      //#region set header

      const response = await findById(id);
      this.formModel.branchId = response.branchId;
      this.formModel.branchName = response.branchName;
      this.formModel.currencyCode = response.currencyCode;
      this.formModel.customerId = response.customerId;
      this.formModel.customerName = response.customerName;
      this.formModel.billTo = response.billTo;
      this.formModel.shipTo = response.shipTo;
      this.formModel.salesId = response.salesId;
      this.formModel.salesName = response.salesName;
      this.formModel.customerPoNumber = response.referenceNo;

      this.setDefaultTop(this.formModel.customerId);

      //#endregion

      //#region set sales order lines

      this.setLinesIc(response);

      if (response.productServices) {
        this.setLinesProdService(response);
      }

      //#endregion
    } catch (error) {
      this.showNotifError("notif_process_fail");
    }
  }

  onChangeCustomer(e: Option<ListContactDataDTO> | undefined): void {
    this.formModel.customerId = "";
    this.formModel.top = 0;

    if (e && e.meta) {
      this.formModel.customerId = e.meta.id;
      this.setAddressOptions(e.meta);
    }

    if (this.formModel.customerId) {
      this.setDefaultTop(this.formModel.customerId);
    }
  }

  setDefaultTop(custId: string): void {
    this.getContactDetail(custId, ({ top = 0 }) => {
      this.formModel.top = top || 0;
    });
  }

  getContactDetail(
    custId: string,
    cb: (payload: DetailContactDataDto) => void
  ): void {
    const { findOne } = useContactData();
    findOne(custId).then(cb);
  }

  onChangeShipTo(value: string | null): void {
    this.updateStoreForm("shipTo", value);
    this.setEachCustomerLoc(value || "");
  }

  setAddressOptions(cust: ListContactDataDTO): void {
    const addresses: AddressDataDto[] = cust.addressDataList;
    const shipToOptions = addresses
      .filter(item => item.shipTo)
      .map<Option>((item, i) => ({
        label: item.address,
        value: item.address,
        key: i,
      }));

    const billToOptions = addresses
      .filter(item => item.billTo)
      .map<Option>((item, i) => ({
        label: item.address,
        value: item.address,
        key: i,
      }));

    this.shipAdressOptions = shipToOptions;
    this.billAdressOptions = billToOptions;

    this.setDefaultAddress(cust);
  }

  /**
   * @description set default ship & bill address
   */
  setDefaultAddress(cust: ListContactDataDTO): void {
    const addresses: AddressDataDto[] = cust.addressDataList;
    const shipAddress = addresses.find(
      item => item.primaryShipTo && item.shipTo
    );
    const billAddress = addresses.find(
      item => item.primaryBillTo && item.billTo
    );
    this.formModel.shipTo = shipAddress?.address || "";
    this.formModel.billTo = billAddress?.address || "";
    this.updateStoreForm("shipTo", this.formModel.shipTo);
    this.setEachCustomerLoc(this.formModel.shipTo);
  }

  onChangeSales(e: Option<ListContactDataDTO> | undefined): void {
    this.formModel.salesId = e?.meta?.id || "";
  }

  onChangeCurrency(e: Option<DataMasterCurrency> | undefined): void {
    this.formModel.currencyCode = e?.meta?.currencyCode || "";

    this.setCurrencyRates();
  }

  setCurrencyRates(): void {
    const { findConversion } = useCurrency();
    const to = this.formModel.currencyCode;
    const base = this.preference.currencyCode;
    this.formModel.currencyRate = ONE;
    if (!to || !base) return;
    findConversion(base, to).then(({ data }) => {
      const [curr] = data;
      this.formModel.currencyRate = curr?.rate || ONE;
    });
  }

  updateStoreForm(key: keyof FormValue, val: FieldGuard<typeof key>): void {
    this.setForm({ [key]: val });
  }

  onChangeSalesType(e: SalesOrderTypeEnum | null): void {
    this.formModel.branchId = "";
    this.formModel.branchName = "";
    this.formModel.customerId = "";
    this.formModel.customerName = "";
    this.formModel.shipTo = "";
    this.formModel.billTo = "";
    this.formModel.salesId = "";
    this.formModel.salesName = "";
    this.formModel.customerPoNumber = "";

    this.formModel.salesType = e || ("" as SalesOrderTypeEnum);

    const keys: number[] = this.getSoLines.map<number>(item => item.key);
    this.deleteRow(keys);

    // reset partial field store form
    this.setForm({
      salesType: e,
      branchId: "",
      shipTo: "",
    });
  }

  onChangeTaxCalc(e: TAX_CALCULATION | null): void {
    this.updateStoreForm("taxCalculation", e);
    if (e === TAX_CALCULATION.NONE) {
      this.restEachTaxCodeLine();
    }
    this.calcSoLines();
  }

  async handleSaveDraft(): Promise<void> {
    const { create, buildCreateDto } = useSalesOrder();
    try {
      this.loading.draft = true;
      const req: SalesOrderCreateRequestDto = buildCreateDto(this.formModel);
      const res = await create(req);
      this.showNotifSuccess("notif_create_success", {
        documentNumber: res.documentNumber,
      });
      this.handleBack();
    } catch (error) {
      this.showNotifError("notif_create_failed");
    } finally {
      this.loading.draft = false;
    }
  }

  async handleCreateSubmit(): Promise<void> {
    const { create, buildCreateDto } = useSalesOrder();
    try {
      this.loading.submit = true;
      const req: SalesOrderCreateRequestDto = buildCreateDto(this.formModel);

      // set document status to Submitted
      req.state = SALES_ORDER_STATUS.SUBMITTED;

      const res = await create(req);
      this.showNotifSuccess("notif_submit_success", {
        documentNumber: res.documentNumber,
      });
      this.handleBack();
    } catch (error) {
      this.showNotifError("notif_submit_fail");
    } finally {
      this.loading.submit = false;
    }
  }

  /**
   * @description submit SO if document already created
   */
  async submitSo(id: string): Promise<void> {
    const { submit, buildUpdateDto } = useSalesOrder();
    try {
      this.loading.submit = true;
      const req: SalesOrderUpdateRequestDto = buildUpdateDto(this.formModel);
      const res = await submit(id, req);
      this.showNotifSuccess("notif_submit_success", {
        documentNumber: res.documentNumber,
      });
      this.handleBack();
    } catch (error) {
      this.showNotifError("notif_submit_fail");
    } finally {
      this.loading.submit = false;
    }
  }

  handleSubmit(): void {
    if (this.isEdit && this.id) {
      this.submitSo(this.id);
    } else if (this.isCreate) {
      this.handleCreateSubmit();
    }
  }

  async handleUpdate(): Promise<void> {
    const { update, buildUpdateDto } = useSalesOrder();
    try {
      this.loading.update = true;
      const req: SalesOrderUpdateRequestDto = buildUpdateDto(this.formModel);
      const res = await update(this.id, req);
      this.showNotifSuccess("notif_update_success", {
        documentNumber: res.documentNumber,
      });
      this.handleBack();
    } catch (error) {
      this.showNotifError("notif_update_fail");
    } finally {
      this.loading.update = false;
    }
  }

  handleBack(): void {
    this.$router.push({ name: "sales.sales-order" });
  }

  getDetailSo(id: string): void {
    const { findById, mapDetailToForm } = useSalesOrder();
    findById(id).then((res: SalesOrderResponseDto) => {
      this.formModel = mapDetailToForm(res);
      this.setSoDetail(res);
    });
  }

  handleCancel(): void {
    const { cancel } = useSalesOrder();
    this.loading.cancel = true;
    cancel(this.id)
      .then(() => {
        this.showNotifSuccess("notif_cancel_success");
        this.handleBack();
      })
      .finally(() => {
        this.loading.cancel = false;
      });
  }

  onHideModal(props: { redirect: boolean }): void {
    this.modalClose.toggle();
    if (!props.redirect) return;
    this.handleBack();
  }

  validateForm(action: Action): void {
    this.form.validate((valid: boolean) => {
      if (!valid) {
        this.showNotifWarning("notif_validation_error");
        return;
      }

      if (this.isLinesInvalid) {
        this.showNotifWarning("lbl_error_validation_part_number_cannot_empty");
        return;
      }

      if (!this.isSalesOther && this.isTaxNone) {
        this.showNotifWarning("lbl_invalid_sales_type_with_tax_none");
        return;
      }

      if (action === "submit") {
        this.handleSubmit();
      } else if (action === "update") {
        this.handleUpdate();
      }
    });
  }
}
