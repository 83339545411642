var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "a-card",
        {
          attrs: {
            title: _vm.isCreate
              ? _vm.$t("lbl_sales_order")
              : _vm.$t("lbl_edit_x", { x: _vm.$t("lbl_sales_order") })
          }
        },
        [
          _c(
            "a-form-model",
            {
              ref: "form",
              attrs: {
                model: _vm.formModel,
                rules: _vm.formRules,
                "label-align": "left",
                "wrapper-col": { span: 12 },
                "label-col": { span: 9 }
              }
            },
            [
              _c(
                "a-row",
                { attrs: { gutter: [16, 16] } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _vm.isEdit
                        ? _c(
                            "a-form-model-item",
                            { attrs: { label: _vm.$t("lbl_document_number") } },
                            [
                              _c("a-input", {
                                attrs: {
                                  value: _vm.formModel.documentNumber,
                                  disabled: ""
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      !!_vm.formModel.status
                        ? _c(
                            "a-form-model-item",
                            { attrs: { label: _vm.$t("lbl_status") } },
                            [
                              _c("a-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.formModel.status,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, "status", $$v)
                                  },
                                  expression: "formModel.status"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_sales_type"),
                            prop: "salesType"
                          }
                        },
                        [
                          _c("SelectSalesType", {
                            attrs: { disabled: _vm.isSubmitted },
                            on: { change: _vm.onChangeSalesType },
                            model: {
                              value: _vm.formModel.salesType,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "salesType", $$v)
                              },
                              expression: "formModel.salesType"
                            }
                          })
                        ],
                        1
                      ),
                      _vm.isSalesRent
                        ? _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t("lbl_internal_contract_number"),
                                prop: "internalContractNumber"
                              }
                            },
                            [
                              _c("SelectAvailableIc", {
                                attrs: { disabled: _vm.isSubmitted },
                                on: { "update:meta": _vm.onChangeIc },
                                model: {
                                  value: _vm.formModel.internalContractNumber,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.formModel,
                                      "internalContractNumber",
                                      $$v
                                    )
                                  },
                                  expression: "formModel.internalContractNumber"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_branch"),
                            prop: "branchName"
                          }
                        },
                        [
                          _c("SelectBranch", {
                            attrs: {
                              disabled: _vm.isSalesRent || _vm.isSubmitted
                            },
                            on: { "update:meta": _vm.onChangeBranch },
                            model: {
                              value: _vm.formModel.branchName,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "branchName", $$v)
                              },
                              expression: "formModel.branchName"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: _vm.$t("lbl_date"), prop: "date" } },
                        [
                          _c("a-date-picker", {
                            staticClass: "w-100",
                            attrs: {
                              format: _vm.DATE_TIME_HOURS_DEFAULT_FORMAT,
                              disabled: _vm.isSubmitted,
                              "show-time": ""
                            },
                            model: {
                              value: _vm.formModel.date,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "date", $$v)
                              },
                              expression: "formModel.date"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_customer"),
                            prop: "customerName"
                          }
                        },
                        [
                          _c("SelectCustomer", {
                            attrs: {
                              disabled: _vm.isSalesRent || _vm.isSubmitted
                            },
                            on: { "update:meta": _vm.onChangeCustomer },
                            model: {
                              value: _vm.formModel.customerName,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "customerName", $$v)
                              },
                              expression: "formModel.customerName"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_ship_address"),
                            prop: "shipTo"
                          }
                        },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: {
                                "option-filter-prop": "children",
                                "filter-option": _vm.useLocalFilter,
                                disabled: _vm.isSalesRent || _vm.isSubmitted,
                                "allow-clear": "",
                                "show-search": ""
                              },
                              on: { change: _vm.onChangeShipTo },
                              model: {
                                value: _vm.formModel.shipTo,
                                callback: function($$v) {
                                  _vm.$set(_vm.formModel, "shipTo", $$v)
                                },
                                expression: "formModel.shipTo"
                              }
                            },
                            _vm._l(_vm.shipAdressOptions, function(data) {
                              return _c(
                                "a-select-option",
                                { key: data.key, attrs: { value: data.value } },
                                [
                                  _c(
                                    "a-tooltip",
                                    [
                                      _c("template", { slot: "title" }, [
                                        _vm._v(" " + _vm._s(data.label) + " ")
                                      ]),
                                      _vm._v(" " + _vm._s(data.label) + " ")
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_bill_address"),
                            prop: "billTo"
                          }
                        },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: {
                                "option-filter-prop": "children",
                                disabled: _vm.isSalesRent || _vm.isSubmitted,
                                "filter-option": _vm.useLocalFilter,
                                "allow-clear": "",
                                "show-search": ""
                              },
                              model: {
                                value: _vm.formModel.billTo,
                                callback: function($$v) {
                                  _vm.$set(_vm.formModel, "billTo", $$v)
                                },
                                expression: "formModel.billTo"
                              }
                            },
                            _vm._l(_vm.billAdressOptions, function(data) {
                              return _c(
                                "a-select-option",
                                { key: data.key, attrs: { value: data.value } },
                                [
                                  _c(
                                    "a-tooltip",
                                    [
                                      _c("template", { slot: "title" }, [
                                        _vm._v(" " + _vm._s(data.label) + " ")
                                      ]),
                                      _vm._v(" " + _vm._s(data.label) + " ")
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_sales_name"),
                            prop: "salesName"
                          }
                        },
                        [
                          _c("SelectSales", {
                            attrs: {
                              disabled: _vm.isSalesRent || _vm.isSubmitted
                            },
                            on: { "update:meta": _vm.onChangeSales },
                            model: {
                              value: _vm.formModel.salesName,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "salesName", $$v)
                              },
                              expression: "formModel.salesName"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            "label-align": "right",
                            label: _vm.$t("lbl_tax_calculation"),
                            prop: "taxCalculation"
                          }
                        },
                        [
                          _c("SelectTaxCalculation", {
                            attrs: { disabled: _vm.isSubmitted },
                            on: { change: _vm.onChangeTaxCalc },
                            model: {
                              value: _vm.formModel.taxCalculation,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "taxCalculation", $$v)
                              },
                              expression: "formModel.taxCalculation"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_customer_po_number"),
                            "label-align": "right",
                            prop: "customerPoNumber"
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: {
                              "allow-clear": "",
                              disabled: _vm.isSubmitted
                            },
                            model: {
                              value: _vm.formModel.customerPoNumber,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "customerPoNumber", $$v)
                              },
                              expression: "formModel.customerPoNumber"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_estimate_delivery_date"),
                            "label-align": "right",
                            prop: "deliveryDate"
                          }
                        },
                        [
                          _c("a-date-picker", {
                            staticClass: "w-100",
                            attrs: {
                              format: _vm.DEFAULT_DATE_FORMAT,
                              disabled: _vm.isSubmitted
                            },
                            model: {
                              value: _vm.formModel.deliveryDate,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "deliveryDate", $$v)
                              },
                              expression: "formModel.deliveryDate"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_currency"),
                            "label-align": "right",
                            prop: "currencyCode"
                          }
                        },
                        [
                          _c("SelectCurrency", {
                            attrs: { disabled: _vm.isSubmitted },
                            on: { "update:meta": _vm.onChangeCurrency },
                            model: {
                              value: _vm.formModel.currencyCode,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "currencyCode", $$v)
                              },
                              expression: "formModel.currencyCode"
                            }
                          })
                        ],
                        1
                      ),
                      !_vm.isIdr
                        ? _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t("lbl_currency_rate"),
                                "label-align": "right",
                                prop: "currencyRate"
                              }
                            },
                            [
                              _c("a-input-number", {
                                staticClass: "w-100",
                                attrs: {
                                  formatter: _vm.formatterNumber,
                                  parser: _vm.reverseFormatNumber,
                                  precision: _vm.storeBaseDecimalPlace,
                                  disabled: _vm.isSubmitted
                                },
                                model: {
                                  value: _vm.formModel.currencyRate,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.formModel,
                                      "currencyRate",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression: "formModel.currencyRate"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_term_of_payment"),
                            "label-align": "right",
                            prop: "top"
                          }
                        },
                        [
                          _c("SelectTermOfPayment", {
                            attrs: { disabled: _vm.isSubmitted },
                            model: {
                              value: _vm.formModel.top,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "top", _vm._n($$v))
                              },
                              expression: "formModel.top"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_notes"),
                            "label-align": "right",
                            prop: "notes"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "extra",
                              fn: function() {
                                return [
                                  _c("character-length", {
                                    attrs: { value: _vm.formModel.notes }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c("a-textarea", {
                            attrs: { disabled: _vm.isSubmitted },
                            model: {
                              value: _vm.formModel.notes,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "notes", $$v)
                              },
                              expression: "formModel.notes"
                            }
                          })
                        ],
                        1
                      ),
                      _vm.isStatusClosed
                        ? _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t("lbl_close_reason"),
                                "label-align": "right"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "extra",
                                    fn: function() {
                                      return [
                                        _c("character-length", {
                                          attrs: {
                                            value: _vm.formModel.closeReason
                                          }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                false,
                                2641990744
                              )
                            },
                            [
                              _c("a-textarea", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.formModel.closeReason,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, "closeReason", $$v)
                                  },
                                  expression: "formModel.closeReason"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("FormTable"),
          _c("a-divider"),
          _c("DisplayTotal"),
          _c("a-divider"),
          _c(
            "div",
            { staticClass: "text-right mt-2" },
            [
              _c(
                "a-space",
                [
                  _c("a-button", { on: { click: _vm.handleBack } }, [
                    _vm._v(" " + _vm._s(_vm.$t("lbl_back")) + " ")
                  ]),
                  _vm.$can("create", "sales-order") && _vm.isCreate
                    ? _c(
                        "a-button",
                        {
                          attrs: { loading: _vm.loading.draft },
                          on: { click: _vm.handleSaveDraft }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("lbl_save_as_draft")) + " "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm.$can("update", "sales-order") && _vm.allowCancel
                    ? [
                        _c(
                          "a-popconfirm",
                          {
                            attrs: {
                              title: _vm.$t("pop_confirmation"),
                              "ok-text": _vm.$t("lbl_yes"),
                              "cancel-text": _vm.$t("lbl_no")
                            },
                            on: { confirm: _vm.handleCancel }
                          },
                          [
                            _c(
                              "a-button",
                              {
                                attrs: {
                                  type: "danger",
                                  loading: _vm.loading.cancel
                                }
                              },
                              [_vm._v(" " + _vm._s(_vm.$t("lbl_cancel")) + " ")]
                            )
                          ],
                          1
                        )
                      ]
                    : _vm._e(),
                  _vm.$can("update", "sales-order") && _vm.allowClose
                    ? _c(
                        "a-button",
                        {
                          attrs: { type: "danger" },
                          on: {
                            click: function($event) {
                              return _vm.modalClose.toggle()
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_close_so")) + " ")]
                      )
                    : _vm._e(),
                  _vm.$can("update", "sales-order") && _vm.allowUpdate
                    ? _c(
                        "a-button",
                        {
                          attrs: {
                            loading: _vm.loading.update,
                            type: "primary"
                          },
                          on: {
                            click: function($event) {
                              return _vm.validateForm("update")
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_update")) + " ")]
                      )
                    : _vm._e(),
                  (_vm.$can("update", "sales-order") ||
                    _vm.$can("create", "sales-order")) &&
                  (_vm.allowSubmit || _vm.isCreate)
                    ? _c(
                        "a-button",
                        {
                          attrs: {
                            loading: _vm.loading.submit,
                            type: "primary"
                          },
                          on: {
                            click: function($event) {
                              return _vm.validateForm("submit")
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_submit")) + " ")]
                      )
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _c("ModalCloseSo", {
        attrs: { "so-id": _vm.id, visible: _vm.modalClose.show },
        on: { cancel: _vm.onHideModal }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }